.cookie-animation-enter {
  opacity: .5;
  transform: translateY(100%);
}
.cookie-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all ease-in-out 500ms;
}
.cookie-animation-exit {
  transform: translateY(0);
  opacity: 1;
}
.cookie-animation-exit-active {
  opacity: .5;
  transform: translateY(100%);
  transition: all ease-in-out 500ms;
}