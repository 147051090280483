@import '../../style/colors';
@import '../../style/mixin';


.information {
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    cursor: pointer;
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #ECE8F0; }
    &:not(.active):hover {
      .information-title span {
        color: $red-dark; }
      .information-arrow {
        background-color: $red-dark;
        width: 57px;
        margin-right: 7px;
        &:after {
          border: 2px solid $red-dark;
          border-bottom: none;
          border-left: none; } } }
    &.active {
      box-shadow: none;
      .information-title span {
        color: $red-dark; }
      .information-arrow {
        display: none; } } }

  &-content {
    &__prev {
      .information-block:last-child {
        box-shadow: inset 0px -1px 0px #ECE8F0; } } }

  &-title {
    font-size: 30px;
    line-height: 35px;
    font-weight: normal;
    margin: 0;
    & span {
      color: $red;
      transition: all ease-in-out .2s; } }
  &-arrow {
    display: inline-block;
    width: 60px;
    height: 2px;
    background-color: $red;
    position: relative;
    margin-top: -1px;
    margin-right: 4px;
    transition: all .2s;
    transition: all ease-in-out .2s;
    &:after {
      content: '';
      height: 26px;
      width: 26px;
      border: 2px solid $red;
      border-bottom: none;
      border-left: none;
      display: block;
      position: absolute;
      right: 3px;
      top: -13px;
      transform: rotate(45deg);
      transition: all ease-in-out .2s; } }

  &-item {
    width: 100%;
    margin: 0;
    & dt {
      display: flex;
      align-items: center;
      &:hover {
        .information-item__title {
          &:hover {
            color: $red;
            .information-item__arrow path {
              stroke: $red-dark; } } }
        .information-item__link {
          width: 30px;
          opacity: .6;
          filter: grayscale(60%);
          padding: 0 5px;
          & svg {
            width: 20px; }
          &:hover {
            background-color: $bgc;
            opacity: 1;
            filter: grayscale(0%);
            & svg path {
              stroke: $red-dark; } } } }

      .information-item__link {
        height: 100%;
        cursor: pointer;
        // width: 15px
        width: 30px;
        opacity: 0;
        overflow: hidden;
        height: 100%;
        transition: all .2s ease-in-out;
        & svg {
          width: 0;
          transition: all .2s ease-in-out;
          & path {
            transition: all .2s ease-in-out; } } }
      .information-item__arrow path {
        transition: all .2s ease-in-out; } }
    & dd {
      margin: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      & .information-item__text {
        max-height: 0;
        opacity: 0;
        padding: 0 15px;
        transition: all .2s ease-in-out; }
      &::-webkit-scrollbar {
        width: 0;
        display: none; } }
    &:not(&:last-child) {
      border-bottom: 1px solid $grey; }
    // &:hover
    //   .information-item__title
    //     color: $red
    //     padding-left: 5px
    //   .information-item__link
    //     width: 30px
    //     opacity: 1
    //     padding: 0 5px
    //     & svg
    //       width: 20px
    //       & path
    //         stroke: $red-dark
    //   .information-item__arrow path
    //     stroke: $red-dark
    &s > span {
      display: block;
      color: $red; }
    &s > ol a {
      white-space: nowrap;
      line-height: 30px; }
    &s > ol li {
      color: $red; }
    &s > ol span {
      color: $dark;
      display: block;
      margin-left: 10px;
      margin-bottom: 10px; }
    &s.border {
      border: 1px solid $grey; }
    &.active {
      background-color: $bgc;
      dd {
        overflow: auto; }
      .information-item__title {
        color: $red-dark; }
      .information-item__arrow {
        transform: rotate(180deg); }
      .information-item__text {
        padding: 25px 15px;
        max-height: max-content;
        opacity: 1;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc( 100% - 30px );
          height: 1px;
          background-color: $grey;
          top: 0;
          left: 15px; } } }
    &__title {
      & span {
        max-width: calc( 100% - 30px ); }
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 20px;
      line-height: 23px;
      color: $dark;
      background-color: transparent;
      cursor: pointer;
      text-align: left;
      outline: none;
      border: none;
      padding: 30px 15px 30px 0;
      transition: all ease-in-out .2s; }
    &__text {
      padding: 25px 15px;
      margin: 0;
      line-height: 19px;
      & span {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      & a {
        white-space: nowrap;
        text-decoration: underline; }
      & b {
        font-weight: bold; }
      & ul li::marker {
        color: $red; }
      & ol li {
        color: $red; }
      & ol span,
      & ul span {
        color: $dark;
        display: block;
        margin-left: 10px;
        margin-bottom: 10px; } }
    &__arrow {
      width: 17px;
      height: 10px; }
    &__arrow, &__arrow path {
      transition: all ease-in-out .2s; } } }



@media (max-width: 991.98px) {
  .information {
    margin-bottom: 20px; } }

@media (max-width: 767.98px) {
  .information {
    &-title {
      padding-right: 5px;
      width: calc( 100% - 40px ); }
    &-arrow {
      width: 40px;
      &:after {
        height: 16px;
        width: 16px;
        top: -8px; } }
    &-block:not(.active):hover {
      .information-arrow {
        width: 37px; } }
    &-item__title {
      padding: 20px 15px; } } }

@media (max-width: 319.98px) {
  .information {
    &-title {
      font-size: 25px;
      line-height: 30px; } } }
