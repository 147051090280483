.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.spinner svg {
  margin: auto; 
  background: none; 
  display: block; 
  shape-rendering: auto;
  width: 91px;
  height: 91px;
}

.spinner-small {
  height: auto;

}
.spinner-button {
  height: auto;
}
.spinner-button svg {
  height: 60px;
  width: 60px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #002137;
  opacity: 1;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: lds-ripple 1s cubic-bezier(0, 0.4, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 90px;
    height: 90px;
    opacity: 0;
  }
}
