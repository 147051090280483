@import '../../../../style/colors';
@import '../../../../style/mixin';

.cars {
  background-color: $bgc;
  padding: 80px 0 17px 0;
  &-title {
    font-size: 70px;
    margin-bottom: 80px;
    @include light; }
  &-block {
    display: flex;
    flex-wrap:  wrap;
    align-items: baseline;
    justify-content: space-between; }
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 49%;
    height: 302px;
    transition: all ease-in-out .3s;
    user-select: none;
    margin-bottom: 100px;
    &:not(&:last-child):hover {
      & .cars-item__title {
        color: $red; }
      & .cars-item__img {
        opacity: .6; } }

    & a {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }

    &__text {
      display: flex;
      align-items: baseline;
      width: 100%; }
    &__title {
      font-size: 30px;
      width: 200px;
      line-height: 37px;
      margin-right: 21px;
      transition: all ease-in-out .3s; }
    &__price {
      &-title {
        font-size: 20px;
        color: $red;
        margin-bottom: 3px; }
      &-number {
        @include light;
        font-size: 55px;
        color: $red;
        letter-spacing: -2px;
        margin-right: 17px; }
      &-units {
        display: inline-block;
        font-size: 20px;
        &_euro {
          display: block;
          width: 38px;
          border-bottom: 1px solid rgba(47, 48, 89, 0.6);
          margin-bottom: -1px; } } }
    &__img {
      transition: all ease-in-out .3s;
      width: 100%;
      & img {
        max-width: 90%; } } } }


@media (min-width: 768px) and (max-width: 991.98px) {
  .cars {
    &-title {
      margin-bottom: 60px; }
    &-item {
      height: 256px; } } }

@media (max-width: 991.98px) {
  .cars {
    padding: 60px 0 0 0;
    &-title {
      @include adaptive-font(80, 40); }
    &-item {
      margin-bottom: 80px;
      &__text {
        flex-direction: column; }
      &__title {
        margin-bottom: 15px; }
      &__price-title, &__price-number, &__price-units {
        line-height: 21px;
        font-size: 18px;
        @include reg; }
      &__price {
        &-title {
          display: inline-block;
          color: $dark;
          margin-right: 5px;
          margin-bottom: 0; }
        &-title, &-number {
          margin-right: 5px; }
        &-number, &-units {
          color: $red; }
        &-units {
          &_euro {
            display: inline-block;
            border: none;
            width: auto;
            &::after {
              content: '/'; } } } } } } }

@media (max-width: 767.98px) {
  .cars {
    padding-top: 40px;
    &-title {
      margin-bottom: 50px; }
    &-item {
      width: 100%;
      height: auto;
      &__price {
        margin-bottom: 20px; }
      &__img {
        text-align: center;
        & img {
          max-width: 100%; } } } } }
