@import '../../../../style/colors';
@import '../../../../style/mixin';

.promo {
  background-color: $bgc;
  background-position: bottom;
  background-repeat: repeat-x;
  overflow: hidden;
  height: calc( 100vh - 145px );
  max-height: 660px;
  min-height: 475px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__title {
      @include light;
      margin: 0;
      font-size: 100px;
      color: $dark;
      letter-spacing: -3px;
      line-height: 110px;
      span {
        @include light;
        color: $red;
        display: block; } }
    &__total {
      font-size: 30px; } }

  &-car {
    display: flex;
    margin: 0 17px 0 19px;
    height: 100%;
    & img {
      height: 100%; }
    &s {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 148px; }
    &__before {
      background-position: right;
      flex-grow: 0.45; }
    &__after {
      flex-grow: 1; }
    &__before, &__after {
      height: 148px;
      background-size: auto 148px; } } }

.total {
  &-title {
    line-height: 35px;
    color: $red;
    margin-top: 13px;
    margin-right: 5px; }
  &-price {
    margin: 1px 0 30px 0;
    &__number {
      @include light;
      font-size: 70px;
      color: $red;
      letter-spacing: -2px;
      margin-right: 18px; }
    &__units {
      display: inline-block;
      transform: translateY(6px);
      &-euro {
        display: block;
        width: 56px;
        padding-bottom: 3px;
        border-bottom: 1px solid rgba(47, 48, 89, .6); } } }
  &-buttons {
    display: flex; }
  &-subtitle {
    font-size: 16px;
    margin-bottom: 39px;
    width: 300px;
    line-height: 20px;
    a {
      color: $red;
      margin-left: 4px; } } }


@media (min-width: 3200px) {
  .promo-car__before {
    flex-grow: 1; } }

@media (min-width: 1920px) and (max-width: 3199.98px) {
  .promo-car {
    margin: 0 19px 0 20px;
    &__before {
      flex-grow: 0.597; } } }

@media (min-width: 1200px) {
  .promo {
    &-block {
      &__total {
        margin-right: 76px; } } } }

@media (max-width: 575.98px) {
  .promo {
    &-cars {
      justify-content: flex-end; }
    &-block__total {
      width: 100%; } } }

@media (max-width: 991.98px) {
  .promo {
    max-height: 760px;
    &-block {
      flex-wrap: wrap;
      flex-direction: column;
      &__title {
        margin-bottom: 20px; } } }
  .total {
    &-title, &-price__number, &-price {
      display: inline-block;
      font-size: 18px;
      line-height: 21px;
      @include reg; }
    &-title {
      color: $dark; }
    &-price {
      color: $red;
      margin-bottom: 20px;
      &__number {
        margin-right: 5px; }
      &__units {
        transform: translateY(0);
        &-euro {
          display: inline-block;
          border: none;
          width: auto;
          &::after {
            content: '/'; } } } }
    &-subtitle {
      margin-bottom: 30px; } } }

@media (max-width: 959.98px) {
  .promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 129px);
    min-height: 870px;
    &-block {
      margin-bottom: 0; } } }

@media (max-width: 767.98px) {
  .promo {
    height: calc(100vh - 109px);
    min-height: 610px;
    &-car {
      margin-left: -20vw; }
    &-block {
      &__title {
        @include adaptive-font(100, 50); } } } }

@media (max-width: 575.98px) {
  .promo {
    min-height: 553px;
    background-size: 250%; }
  .total {
    &-title, &-price {
      margin-top: 0; } } }

@media (max-width: 360.98px) {
  .promo {
    min-height: 520px;
    background-size: 160%;
    &-car {
      height: 90px;
      &s {
        height: 90px; }
      &__before, &__after {
        height: 90px;
        background-size: auto 90px;
        background-repeat: repeat-x; } } } }

@media (max-width: 320.98px) {
  .promo {
    height: calc(100vh - 99px);
    min-height: 469px;
    background-size: 190%; } }
