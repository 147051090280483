@import '../../style/colors';

.footer {
  background-color: $dark;
  color: #fff;
  padding: 60px 0;
  font-size: 16px;
  &-block {
    display: flex;
    margin-bottom: 148px;
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 55px; }
    &__contacts {
      display: flex;
      justify-content: space-between; }
    &__remark {
      display: flex;
      align-items: center; } }
  &-new {
    margin-bottom: 40px;
    &:hover {
      .footer-new__title {
        color: rgba(255, 255, 255, 0.7); }
      .footer-new__date {
        color: rgba(255, 255, 255, 0.4); } }
    &__link {
      display: flex;
      width: 100%; }

    &s {
      flex-grow: 0.365;
      box-shadow: inset -1px 0px 0px rgba(255, 255, 255, 0.2);
      padding-top: 2px;
      padding-right: 33px;
      margin-right: 30px;
      &__title {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 62px; } }
    &:last-child {
      margin-bottom: 0px; }
    &__date {
      width: 60px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 20px;
      transition: all ease-in-out .2s;
      margin-right: 50px; }
    &__title {
      width: 240px;
      line-height: 19px;
      color: #fff;
      transition: all ease-in-out .2s;
      line-height: 20px; } }

  &-info {
    flex-grow: 3; }
  &-nav {
    display: flex;
    &__item {
      color: #fff;
      transition: all ease-in-out .2s;
      margin-right: 20px;
      &:hover, &.active {
        color: rgba(255, 255, 255, 0.7); }
      &:last-child {
        margin-right: 0; } } }
  &-contact {
    color: #fff;
    display: block;
    transition: all ease-in-out .2s;
    &:hover {
      color: rgba(255, 255, 255, .7); }
    &s {
      font-size: 30px;
      line-height: 35px; }
    &__phone {
      margin-bottom: 2px; }
    &__mail {
      margin-bottom: 35px; }
    &__address {
      font-size: 16px;
      line-height: 20px;
      &:hover {
        color: #fff; } } }
  &-button {
    display: inline-block;
    &:first-child {
      margin-right: 8px; }
    &s {
      margin-top: 6px; } }
  &-remark {
    flex-grow: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__info {
      flex-grow: 0.95;
      & a {
        color: #ffffff;
        transition: all ease-in-out .2s;
        margin-right: 20px;
        &:hover {
          color: rgba(255, 255, 255, 0.5); } } }
    &__social {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease-in-out .2s;
      margin: 0 5px;
      cursor: pointer;
      & img {
        transition: all ease-in-out .2s;
        max-width: 24px;
        max-height: 24px; }
      &:hover img {
        opacity: .7; }
      &s {
        display: flex; } }
    &__author {
      color: #fff;
      transition: all ease-in-out .2s;
      &:hover {
        color: rgba(255, 255, 255, 0.7); } } } }


@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer {
    &-block__nav {
      align-items: flex-start; }
    &-new {
      &s {
        padding-right: 15px;
        margin-right: 15px; }
      &__date {
        margin-right: 15px; }
      &__title {
        width: 210px; } }
    &-nav {
      display: flex;
      flex-wrap: wrap;
      height: 70px;
      &__item {
        flex-basis: 33%;
        margin: 0 0 15px; } } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer {
    padding-bottom: 40px;
    &-nav {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &__item {
        margin: 0 35px 15px 0; } }
    &-block {
      &__nav {
        align-items: baseline;
        margin-bottom: 30px; }
      &__contacts {
        flex-direction: column;
        position: relative; } }
    &-buttons {
      position: absolute;
      bottom: -88px; }
    &-remark {
      flex-grow: 1;
      &__info {
        flex-grow: 1; } } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .footer {
    &-new {
      &s {
        padding-right: 15px;
        margin-right: 15px; }
      &__date {
        margin-right: 15px; }
      &__title {
        width: 210px; } }
    &-remark {
      flex-grow: 3;
      &__info {
        flex-grow: 1; } } } }

@media (max-width: 767.98px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 30px;
    &-block {
      flex-direction: column;
      margin-bottom: 50px;
      &__nav {
        align-items: baseline;
        margin-bottom: 50px; }
      &__contacts {
        flex-direction: column; }
      &__remark {
        flex-direction: column;
        align-items: baseline; } }
    &-new {
      &s {
        box-shadow: inset 0px -1px 0px rgba(255, 255, 255, .2);
        padding: 0;
        padding-bottom: 40px;
        margin-right: 0;
        margin-bottom: 40px;
        &__title {
          margin-bottom: 40px; } }
      &__title {
        width: 100%; }
      &__date {
        width: 130px;
        margin-right: 25px; } }
    &-nav {
      display: flex;
      flex-direction: column;
      height: 250px;
      flex-wrap: wrap;
      &__item {
        padding: 10px 0; } }
    &-contact {
      &__mail {
        margin-bottom: 16px; }
      &s {
        margin-bottom: 40px; } }
    &-buttons {
      margin-top: 0; }
    &-remark {
      flex-direction: column;
      align-items: baseline;
      &__info {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        & a {
          padding: 9px 0; } }
      &__socials {
        margin-bottom: 30px; } } } }

@media (max-width: 419.98px) {
  .footer-new__date {
    width: 120px;
    margin-right: 0px; } }

@media (max-width: 324.98px) {
  .footer-button:first-child {
    margin-right: 0; } }

@media (max-width: 299.98px) {
  .footer {
    &-new {
      &__link {
        flex-direction: column; }
      &__date {
        margin-bottom: 10px; } }
    &-nav {
      height: 270px; } } }
