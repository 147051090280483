@import '../../../../style/colors';
@import '../../../../style/mixin';

.promo {
  background-color: $dark;
  background-position: bottom;
  background-repeat: repeat-x;
  overflow: hidden;
  color: #ffffff;
  &_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 90px;

    &__title {
      @include light;
      margin: 0;
      font-size: 100px;
      color: #ffffff;
      letter-spacing: -3px;
      line-height: 110px;
      margin-bottom: 30px;
      span {
        @include light;
        color: $red;
        display: block; } }
    &__subtitle {
      max-width: 300px;
      font-size: 20px;
      line-height: 24px; }
    &__info {
      display: flex;
      flex-direction: column; } }

  &_car {
    & img {
      display: block;
      max-width: 755px;
      width: 100%;
      margin-left: auto;
      margin-right: auto; } } }

.info {
  &_icon {
    width: 20px;
    height: 20px;
    fill: #ffffff;
    margin-bottom: 15px; }
  &_title {
    width: 190px;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 35px; }

  &_buttons {
    margin-bottom: 20px; }

  &_subtitle {
    font-size: 16px;
    margin-bottom: 39px;
    width: 200px;
    line-height: 20px;
    a {
      color: $red;
      margin-left: 4px; } } }


@media (max-width: 991.98px) {
  .promo {
    &_block {
      flex-wrap: wrap;
      &__title {
        margin-bottom: 20px; } } }
  .info {
    &_title {
      display: inline-block;
      font-size: 18px;
      line-height: 21px;
      @include reg; } } }

@media (max-width: 959.98px) {
  .container {
    height: 100%; }
  .promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 129px);
    min-height: 870px;
    &_block {
      height: 100%;
      margin-bottom: 0;
      &__title {
        margin-bottom: 25px; }
      &__main {
        align-self: center; }
      &__info {
        align-self: center; } } }
  .info {
    &_icon {
      display: none; }
    &_title {
      width: 100%;
      margin-bottom: 20px; } } }


@media (max-width: 767.98px) {
  .promo {
    height: calc(100vh - 109px);
    min-height: 610px;
    &_block {
      &__title {
        @include adaptive-font(100, 50); }
      &__subtitle {
        font-size: 18px;
        line-height: 21px; } } }
  .info {
    &_title {
      font-size: 16px;
      line-height: 19px; } } }

@media (max-width: 575.98px) {
  .promo {
    min-height: 553px;
    background-size: 250%; }
  .info {
    &_title {
      margin-top: 0; } } }

@media (max-width: 360.98px) {
  .promo {
    min-height: 520px;
    background-size: 160%;
    &_car {
      &s {
        height: 90px; }
      &__before, &__after {
        height: 90px;
        background-size: auto 90px;
        background-repeat: repeat-x; } } } }

@media (max-width: 320.98px) {
  .promo {
    height: calc(100vh - 99px);
    min-height: 469px;
    background-size: 190%; } }
