.popup-animation-enter {
  opacity: .5;
}
.popup-animation-enter .popup-wrapper {
  transform: translateY(25%);
}

.popup-animation-enter-active {
  opacity: 1;
  transition: all ease-in-out 500ms;
}
.popup-animation-enter-active .popup-wrapper {
  transform: translateY(0);
  transition: all ease-in-out 500ms;
}

.popup-animation-exit {
  opacity: 1;
}
.popup-animation-exit .popup-wrapper {
  transform: translateY(0);
}

.popup-animation-exit-active {
  opacity: .5;
  transition: all ease-in-out 500ms;
}
.popup-animation-exit-active .popup-wrapper {
  transform: translateY(25%);
  transition: all ease-in-out 500ms;
}