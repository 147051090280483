@import '../../../../style/colors';
@import '../../../../style/mixin';

.map {
  height: 711px;
  position: relative;
  &-content {
    width: 100%;
    height: 100%; }

  &-cluster div {
    color: #ffffff !important; }

  .gm-style-iw-t {
    display: none; }

  &-custom {
    width: 100%;
    height: 100%; }
  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1; }
  &-checkbox__title {
    transition: all ease-in-out .2s;
    cursor: pointer;
    &.active {
      color: $red; } }

  &-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 30px;
    // top: 20px
    top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out .2s;
    transform: rotate(180deg);
    &.active {
      transform: rotate(0); }
    & svg {
      width: 20px;
      height: 20px;
      user-select: none;
      pointer-events: none; } }
  &-block {
    width: 410px;
    background-color: #fff;
    margin-top: 55px;
    user-select: none;
    pointer-events: all;
    &__header {
      box-shadow: inset 0px -1px 0px #ECE8F0;
      // padding: 25px 0 16px 45px
      padding: 16px 0 16px 30px;
      display: flex;
      position: relative;
      align-items: center; }
    &__switch {
      position: relative;
      display: inline-block;
      width: 48px;
      height: 24px;
      margin: 0 17px 0 18px; }
    &__input {
      display: none;
      &:checked + .map-block__slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px); } }
    &__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ECE8F0;
      transition: all ease-in-out .4s;
      border-radius: 12px;
      height: 24px;
      width: 48px;
      &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 3px;
        background-color: $red;
        transition: all ease-in-out .4s;
        border-radius: 50%; } }
    &__content {}
    &__body {
      padding-left: 40px;
      max-height: 0;
      opacity: 0;
      transition: all ease-in-out .2s, max-height linear .2s;
      pointer-events: none;
      &.active {
        padding: 20px 0 20px 40px;
        max-height: 100%;
        opacity: 1;
        pointer-events: all; } }
    &__title {
      @include light;
      font-size: 60px;
      letter-spacing: -2px;
      margin: 0 0 21px 0; }
    &__subtitle {
      width: 170px;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 40px; }
    &__info {
      color: $red;
      padding-bottom: 20px;
      &-title {
        font-size: 20px;
        display: block;
        margin-bottom: 13px; }
      &-count {
        @include light;
        font-size: 70px;
        vertical-align: super;
        float: left;
        line-height: 57px;
        letter-spacing: -2px;
        margin-right: 12px; }
      &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #ECE8F0;
        margin-top: -2px;
        & img {
          margin-top: -4px;
          margin-right: -3px; } } }
    & dl, & dt, & dd {
      margin: 0; }
    &__item {
      font-size: 14px;
      line-height: 17px;
      color: $dark;
      &:not(&:last-child) {
        margin-bottom: 24px; }
      &s {
        width: 100%;
        font-size: 14px;
        line-height: 17px;
        padding-right: 40px; }
      &-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 16px;
        line-height: 20px;

        background-color: transparent;
        cursor: pointer;
        text-align: left;
        outline: none;
        border: none;
        transition: all ease-in-out .2s;
        & > div > div {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          margin-right: 12px;
          &.green {
            background: rgba(0, 151, 55, 0.3);
            border: 1px solid #00A53C; }
          &.blue {
            background: rgba(0, 178, 255, 0.3);
            border: 1px solid #00B2FF; }
          &.yellow {
            background: rgba(255, 184, 0, 0.3);
            border: 1px solid #FFB800; }
          &.red {
            background: rgba(210, 32, 32, 0.3);
            border: 1px solid #D22020; } } }
      &-title {
        display: flex;
        align-items: center;
        & > span {
          transition: all ease-in-out .2s; } }
      &__arrow {
        width: 17px;
        height: 10px; }
      &__arrow, &__arrow path {
        transition: all ease-in-out .2s; }
      &__text {
        margin-top: 12px;
        &-link {
          white-space: nowrap;
          text-decoration: underline; } }
      & dd {
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        &::-webkit-scrollbar {
          width: 0;
          display: none; } }
      & dt:hover {
        .map-block__item-title span {
          color: $red; }
        .map-block__item__arrow path {
          stroke: $red-dark; } }
      &.active {
        .map-block__item__arrow {
          transform: rotate(180deg); } } } } }


@media (min-width: 768px) and (max-width: 991.98px) {
  .map {
    &-block__title {
      @include adaptive-font(60, 50); } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .map {
    &-block {
      width: 360px;
      &__header, &__body, &__body.active {
        padding-left: 20px; } }
    &-arrow {
     right: 20px; } } }

@media (max-width: 767.98px) {
  .map {
    display: flex;
    flex-direction: column;
    height: auto;
    & > div:last-child {
      height: 470px !important; }
    &-container {
      position: relative;
      height: auto;
      & > .container {
        width: 100vw;
        margin: 0; } }
    &-block {
      margin-top: 0;
      width: 100%;
      &__header, &__body, &__body.active {
        width: 100vw;
        display: flex;
        justify-content: center;
        padding: 0 15px; }
      &__header {
        padding: 20px 15px; }
      &__title {
        display: none; }
      &__subtitle {
        width: 100%;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px; }
      &__info {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px; }
      &__items {
        padding: 30px 0; } }
    &-arrow {
      display: none; }
    &-checkbox__title {
      font-size: 14px; } } }
