@import '../../style/colors';

.language-select {
  width: 108px;
  height: 34px;
  padding-left: 7px;
  background: transparent;
  border: 1px solid rgba(47, 48, 89, 0.1);
  border-radius: 5px;
  outline: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-top: 1px;
  transition: all ease-in-out .2s;
  &:hover {
    border: 1px solid rgba(47, 48, 89, 0.2); }
  &--dark {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    & option {
      color: $dark; }
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2); } } }
