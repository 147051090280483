@import '../../style/colors';
@import '../../style/mixin';


.about {
  &-description {
    font-size: 16px;
    line-height: 20px;
    // max-width: 630px
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; } }

  &-title {
    @include light;
    margin: 0;
    font-size: 100px;
    line-height: 110px;
    color: $dark;
    letter-spacing: -3px;
    margin-bottom: 60px;
    span {
      @include light;
      color: $red;
      display: block; } }

  &-feature {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    box-shadow: inset 0px -1px 0px #ECE8F0;
    &:hover {
      .about-feature__title span {
        color: $red-dark; } }
    &s {
      margin-bottom: 40px; }
    &__img {
      width: 52px;
      height: 52px;
      margin-right: 50px;
      transform: translateY(-25%); }
    &__title {
      flex-grow: 1;
      font-size: 30px;
      margin-right: 40px;
      & span {
        transition: all ease-in-out .3s;
        color: $red; } }
    &__description {
      width: 300px;
      line-height: 19px; } } }


@media (max-width: 1199.98px) {
  .about {
    &-title {
      @include adaptive-font(100, 50); } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .about {
    &-feature {
      &__img, &__title {
        margin-right: 15px; }
      &__title {
        width: 40%; }
      &__description {
        width: 50%; } } } }

@media (max-width: 767.98px) {
  .about {
    &-description {
      margin-bottom: 30px; }
    &-title {
      margin-bottom: 30px; }
    &-feature {
      flex-direction: column;
      padding: 20px 0;
      &s {
        margin-bottom: 20px; }
      &__img {
        margin-right: 0;
        transform: none;
        margin-bottom: 20px; }
      &__title {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px; }
      &__description {
        width: 100%; } } } }
