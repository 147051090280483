@import '../../style/colors';
@import '../../style/mixin';

.news {
  &-block {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    cursor: pointer;
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #ECE8F0; }
    &:hover {
      .news-title span {
        color: $red-dark; }

      .news-date__day {
        color: $red-dark; }
      .news-arrow {
        background-color: $red-dark;
        width: 57px;
        margin-right: 7px;
        &:after {
          border: 2px solid $red-dark;
          border-bottom: none;
          border-left: none; } } }
    &__date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; } }

  &-date {
    display: flex;
    &__day {
      font-size: 70px;
      line-height: 72px;
      color: $red;
      transition: all ease-in-out .2s;
      margin-right: 15px; }
    &__month span {
      display: block; } }

  &-title {
    font-size: 30px;
    line-height: 35px;
    font-weight: normal;
    width: 90%;
    margin: 0;
    & span {
      margin-left: 5px;
      color: $red;
      font-size: 16px;
      line-height: 19px;
      transition: all ease-in-out .2s; } }
  &-arrow {
    display: inline-block;
    width: 60px;
    height: 2px;
    background-color: $red;
    position: relative;
    margin-top: -1px;
    margin-right: 4px;
    transition: all .2s;
    transition: all ease-in-out .2s;
    &:after {
      content: '';
      height: 26px;
      width: 26px;
      border: 2px solid $red;
      border-bottom: none;
      border-left: none;
      display: block;
      position: absolute;
      right: 3px;
      top: -13px;
      transform: rotate(45deg);
      transition: all ease-in-out .2s; } }

  &-btn {
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    background: $red;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    padding: 18px;
    border-radius: 5px;
    margin-top: 40px;
    transition: all ease-in-out .2s;
    &:hover {
      background: $red-dark; } }

  &-item {
    &__date {
      margin-bottom: 25px; }
    &__title {
      @include light;
      font-size: 70px;
      line-height: 80px;
      letter-spacing: -2px;
      margin: 0 0 30px;
      & span {
        font-weight: bold; } }
    &__img {
      margin-bottom: 30px;
      max-width: 100%; }
    &__text {
      font-size: 20px;
      line-height: 26px;
      & a {
        color: $red; }
      & strong {
        font-weight: 700; }
      & ol, & ul {
        list-style: none none;
        margin: 0;
        padding: 0; }
      & ol {
        counter-reset: myCounter; }
      & li {
        padding-left: 40px;
        margin-bottom: 20px;
        position: relative;
        font-size: 16px;
        line-height: 23px;
        &::before {
          display: block;
          position: absolute;
          left: 0; } }
      & ul li::before {
        content: '';
        height: 10px;
        width: 10px;
        top: 8px;
        border-radius: 50%;
        background-color: $red; }
      & ol li::before {
        counter-increment: myCounter;
        content: counter(myCounter)'.';
        color: $red; } } } }




@media (max-width: 767.98px) {
  .news {
    &-block {
      &:hover {
        .news-arrow {
          width: 37px; } }
      &__date {
        flex-direction: column;
        align-items: start;
        margin-bottom: 30px; } }
    &-date {
      margin-bottom: 20px;
      &__day {
        font-size: 16px;
        line-height: 19px;
        margin-right: 5px; }
      &__month span {
        display: inline-block;
        margin-right: 5px; } }
    &-arrow {
      width: 40px;
      &:after {
        height: 16px;
        width: 16px;
        top: -8px; } }

    &-btn {
      font-size: 16px;
      line-height: 19px;
      padding: 15px;
      margin-top: 30px; }

    &-item {
      &__date {
        margin-bottom: 15px; }
      &__title {
        @include adaptive-font(70, 40); } } } }


@media (max-width: 319.98px) {
  .news {
    &-title {
      font-size: 25px;
      line-height: 30px; } } }

