@import '../../../../style/colors';

.referral {
  &-main {
    padding: 60px 0;
    &__wrapper {
      display: flex;
      background: $bgc; }
    &__title {
      width: 60%;
      margin: 0 0 40px; }
    &__item {
      &s {
        display: flex; }
      width: 410px;
      height: 268px;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 30px;
      &:not(:last-child) {
        margin-right: 30px; }
      &-title {
        margin: 0;
        margin-bottom: 20px;
        & span {
          display: block;
          color: $red;
          font-size: 70px;
          line-height: 86px;
          margin-top: 5px; } }
      &-subtitle {
        font-size: 16px;
        line-height: 22px;
        & span {
          color: $red; } } } }
  &-instruction {
    & .guide-item {
      &:first-child {
        padding-top: 0; } }
    &__text {
      & h3 {
        margin: 0 0 30px; }
      & p {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
        & span {
          color: $red; } } }
    &__aside {
      width: 200px;
      margin-bottom: 30px;
      &-video {
        position: relative;
        height: 200px;
        width: 200px;
        margin-bottom: 16px;
        cursor: pointer;
        & img {
          width: 200px;
          height: 200px;
          border-radius: 16px;
          user-select: none; }
        & svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          user-select: none; } }
      &-text {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(47, 48, 89, 0.12);
        margin-bottom: 30px; } } } }


@media (min-width: 768px) and (max-width: 991.98px) {
  .referral {
    &-main {
      padding: 0 0 60px;
      &__title {
        width: 100%;
        text-align: center; }
      &__wrapper > .container {
        width: calc(100% - 60px); } }
    &-instruction__aside {
      width: 100%;
      display: flex;
      align-items: center;
      &-wrapper {
        width: 100%; }
      &-video {
        margin: 0 25px 0 0; }
      &-text {
        padding-bottom: 20px;
        margin-bottom: 20px; } } } }

@media (max-width: 767.98px) {
  .referral {
    &-main {
      padding: 0 0 30px;
      &__title {
        width: 100%;
        text-align: center;
        margin: 0 0 20px; }
      &__item {
        width: 100%;
        &s {
          flex-direction: column; }
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 15px; } } }
    &-instruction__aside {} } }


@media (min-width: 576px) and (max-width: 767.98px) {
  .referral {
    &-instruction__aside {
      width: 100%;
      display: flex;
      align-items: center;
      &-wrapper {
        width: 100%; }
      &-video {
        margin: 0 25px 0 0; }
      &-text {
        padding-bottom: 20px;
        margin-bottom: 20px; } } } }

@media (max-width: 575.98px) {
  .referral-instruction__aside {
    width: 100%; } }
