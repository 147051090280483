@import '../../style/colors';

.nav {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    display: none; }
  &-overlay {
    width: 100%;
    height: 100%; }
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 65px 30px 40px 30px;
    width: 495px;
    min-height: 100%;
    right: 0;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 2px 26px 0 rgba(47, 48, 89, .15); }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
  &-close {
    color: $red;
    cursor: pointer;
    & svg {
      width: 36px;
      height: 36px; }
    & svg path {
      fill: $red; } }
  &-link {
    margin: 0;
    padding: 0;
    transition: all ease-in-out .2s;

    & .active a {
      color: $red; }
    & li {
      list-style-type: none;
      font-size: 30px;
      line-height: 55px;
      transition: all ease-in-out .2s;
      & a {
        display: inline-block;
        width: 100%;
        height: 100%;
        &:hover {
          color: $red-dark; } } }
    &s {
      &__top {
        margin-bottom: 25px; }
      &__bottom {
        margin-bottom: 40px; }
      &__buttons {
        margin-bottom: 15px; } } }
  &-contact {
    & a {
      text-decoration: none;
      color: $dark; }
    &__address {
      margin-top: 20px; }
    &s {} } }


@media (max-width: 767.98px) {
  .nav {
    &-wrapper {
      width: 100%; }
    &-link {
      & li {
        // line-height: 48px
        line-height: 30px;
        padding: 9px 0; } } } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .nav {
    &-wrapper {
      padding: 40px 20px 30px 20px; } } }

@media (max-width: 575.98px) {
  .nav {
    &-wrapper {
      padding: 30px 15px 15px 15px; }
    &-header {
      margin-bottom: 15px; }
    &-links {
      &__buttons {
        margin-bottom: 15px; }
      &__bottom {
        margin-bottom: 40px; } } } }

@media (max-width: 500.98px) {
  .nav {
    &-contact {
      &s {
        position: relative; }
      &__phone, &__mail, &__address {
        width: 44vw; }
      &__phone {
        margin-bottom: 7px; }
      &__address {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -1px; } } } }

@media (max-width: 320.98px) {
  .nav {
    &-wrapper {
      padding: 15px; }
    &-header {
      margin-bottom: 15px; }
    &-links {
      &__top {
        margin-bottom: 25px; }
      &__buttons {
        margin-bottom: 15px; }
      &__bottom {
        margin-bottom: 15px; } } } }

@media (min-width: 279.98px) and (max-width: 320.98px) {
  .nav {
    &-contact__phone {
      font-size: 15px; } } }

@media (max-width: 319.98px) {
  .nav-link li {
    font-size: 25px; } }
