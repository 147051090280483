@import '../../style/colors';

.legal {
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    cursor: pointer;
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #ECE8F0; }
    &:hover:not(&.links) {
      .legal-title span {
        color: $red-dark; }
      .legal-icon {
        fill: $red-dark;
        margin-right: 7px; } }

    &.links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 0;
      & .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 30px 0;
        transition: all .2s ease-in-out;
        & svg {
          color: $red;
          transition: all .2s ease-in-out; }
        &:hover {
          & svg {
            color: $red-dark; }
          margin-right: 7px;
          .legal-title span {
            color: $red-dark; } } }
      & .file {
        display: block;
        padding: 30px 0 30px 30px;
        transition: all .2s ease-in-out;
        &:hover {
          & svg {
            fill: $red-dark; }
          padding-right: 7px;
          padding-left: 23px; } } } }



  &-icon {
    fill: $red;
    width: 28px;
    margin-right: 4px;
    transition: all ease .2s; }

  &-title {
    font-size: 30px;
    line-height: 35px;
    font-weight: normal;
    margin: 0;
    & span {
      color: $red;
      transition: all ease-in-out .2s; } } }


@media (max-width: 991.98px) {
  .legal {
    margin-bottom: 20px; } }

@media (max-width: 767.98px) {
  .legal {
    &-title {
      padding-right: 5px;
      width: calc( 100% - 40px ); }
    &:hover {
      .legal-icon {
        margin-right: 4px; } } } }

@media (max-width: 319.98px) {
  .legal {
    &-title {
      font-size: 25px;
      line-height: 30px; } } }


.legal-privacy {
  overflow: hidden;
  & .overflow {
    overflow-x: auto;
    width: 100%; }
  & h1 {
    text-decoration: underline; }
  & h1, h2, th {
    text-align: center;
    font-weight: 600; }
  & h3 {
    font-weight: 600; }
  & table, th, td {
    border: 1px solid;
    vertical-align: baseline;
    border-collapse: collapse; }
  & th, td {
    padding: 5px; }
  & td p {
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } }
  & a {
    text-decoration: underline;
    color: blue; } }
