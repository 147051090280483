@import '../../style/colors';

.promotion {
  width: 100%;
  background-color: $red;
  transition: all ease-in-out .2s;
  & a {
    display: block;
    padding: 15px 0; }
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    & span {
      margin-right: 10px; } }
  &-arrow {
    display: inline-block;
    width: 39px;
    height: 1px;
    background-color: #ffffff;
    position: relative;
    margin-top: -1px;
    margin-right: 4px;
    transition: all .2s;
    transition: all ease-in-out .2s;
    &:after {
      content: '';
      height: 16px;
      width: 16px;
      border: 1.9px solid #ffffff;
      border-bottom: none;
      border-left: none;
      display: block;
      position: absolute;
      right: 3px;
      top: -9px;
      transform: rotate(45deg);
      transition: all ease-in-out .2s; } }

  &:hover {
    background-color: #c90845;
    .promotion-arrow {
      width: 34px;
      margin-right: 9px; } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .promotion {
    &-block {
      font-size: 18px; } } }

@media (max-width: 767.98px) {
  .promotion {
    &-block {
      font-size: 16px; } } }
