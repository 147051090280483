.features {
  overflow-x: auto;
  padding: 56px 0px;
  &::-webkit-scrollbar {
    width: 0;
    display: none; }
  &-block {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
 }    // height: 78px
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    &__icon {
      width: 80px;
      height: 80px; }
    &__text {
      width: 180px;
      font-size: 20px;
      line-height: 25px;
      margin-top: 8px;
      margin-left: 15px; } } }


@media (max-width: 991.98px) {
  .features {
    &-block {
      height: auto; }
    &-item {
      flex-direction: column;
      align-items: baseline;
      &__text {
        font-size: 18px;
        margin-left: 0; } } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .features {
    padding: 30px 0;
    overflow: hidden;
    & .container {
      width: 100vw;
      max-width: 720px; }
    &-item {
      &__text {} } } }

@media (max-width: 767.98px) {
  .features {
    padding: 60px 0 20px;
    &-item {
      margin-right: 50px;
      &__icon {
        width: 60px;
        height: 60px; }
      &:last-child {
        margin-right: 0; } } } }

@media (max-width: 575.98px) {
  .features {
    padding: 60px 0 20px;
    &-item {
      margin-right: 30px;
      &__icon {
        width: 50px;
        height: 50px; } } } }
