@import '../../style/colors';

.sc__wrapper {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.sc__container {
  position: relative;
  overflow: hidden;
  max-height: 600px;
  max-width: 800px;
  margin: auto; }

.sc__container > img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sc__container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; }

.error {
  color: $red-dark;
  font-size: 20px;
  height: fit-content; }

.event {
  background-color: $bgc;
  overflow: hidden;
  padding-bottom: 50px;
  &-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    &__flip {
      margin-bottom: 50px;
      min-height: calc(100dvh - 163px - 572px);
      position: relative;
      &-desc {
        position: absolute;
        top: 20px;
        right: 104%;
        // pointer-events: none
        visibility: hidden;
        opacity: 0;
        transition: all ease-in-out .3s;
        max-height: 0;
        & span {
          display: block;
          width: 270px;
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 30px; }
        & img {
          width: 100px;
          height: 100px;
          position: absolute;
          top: 30%;
          left: 80%;
          pointer-events: none; }
        &.active {
          visibility: visible;
          opacity: 1;
          max-height: 300px; } }
      &_container {
        width: 100%;
        height: 600px;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        & .ReactFlipCard__flipCard {
          display: flex;
          justify-content: center;
          & .ReactFlipCard__flipCardFront {
            cursor: pointer; }
          & img {
            width: 400px;
            height: 600px;
            overflow: hidden;
            border-radius: 20px;
            border: 2px solid #2F3059; } } } }

    &__scratch {
      width: 600px;
      height: 600px;
      margin-bottom: 20px;
      position: relative;
      &-desc {
        position: absolute;
        top: 20px;
        right: 105%;
        // pointer-events: none
        visibility: hidden;
        opacity: 0;
        transition: all ease-in-out .3s;
        & div {
          position: relative; }
        & span {
          display: block;
          width: 190px;
          font-size: 20px;
          font-weight: 400; }
        & img {
          width: 100px;
          height: 100px;
          position: absolute;
          top: 30%;
          left: 80%;
          pointer-events: none; }
        &.active {
          visibility: visible;
          opacity: 1; } } }


    &__promo {
      display: none;
      flex-direction: column;
      align-items: center;
      &.active {
        display: flex; }
      &-title {
        font-size: 20px;
        user-select: none;
        margin-bottom: 10px; }
      &-promo {
        font-size: 30px;
        color: $red-dark;
        margin-bottom: 20px;
        cursor: pointer; }
      &-text {
        user-select: none;
        margin-bottom: 10px; }
      &-next {
        user-select: none;
        & .date {
          display: inline;
          font-size: 18px; } } } } }


@media (max-width: 1100.98px) {
  .event-block__scratch-desc {
    right: 103%; } }

@media (max-width: 1050.98px) {
  .event-block__scratch-desc {
    right: 100%;
    & img {
      height: 80px;
      width: 80px;
      top: 35px;
      left: 80%; } } }

@media (max-width: 991.98px) {
  .event-block__scratch {
    padding-top: 40px;
    margin-bottom: 60px;
    &-desc {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      & span {
        width: 100%;
        text-align: center; }
      & img {
        display: none; } } }
  .ReactFlipCard__flipCard {
    width: auto; }
  .event-block__flip-desc {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    & span {
      width: 100%;
      margin: 0; }
    & img {
      display: none; } } }

@media (max-width: 767.98px) {
  .event-block__scratch {
    width: 400px;
    height: 400px;
    padding-top: 60px;
    margin-bottom: 80px; } }

@media (max-width: 575.98px) {
  .event-block__scratch {
    width: 300px;
    height: 300px; }
  .event-block__flip_container {
    height: 450px;
    & .ReactFlipCard__flipCard img {
      width: 300px;
      height: 450px; } } }

@media (max-width: 310.98px) {
  .header-title__event {
    font-size: 34px !important; }
  .event-block__scratch {
    width: 250px;
    height: 250px;
    padding-top: 80px; }
  .event-block__flip_container {
    height: 360px;
    & .ReactFlipCard__flipCard img {
      width: 240px;
      height: 360px; } } }
