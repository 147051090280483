.error {
  padding: 40px 55px;
  height: 100vh;
  background-position: 90% 100%;
  background-repeat: no-repeat;
}

.error-logo {
  height: 69px;
  width: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ECE8F0;
  border-radius: 16px;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 80px;
}
.error-logo svg {
  width: 80%;
}

.error-title {
  font-size: 100px;
  letter-spacing: -3px;
  color: #E01254;
  margin-bottom: 50px;
}

.error-cookies {
  font-size: 70px;
  letter-spacing: -3px;
  margin-bottom: 40px;
}
.error-cookies span {
  color: #E01254;
}

.error-subtitle {
  font-size: 16px;
  line-height: 22px;
}
.error-subtitle span {
  display: block;
  color: #E01254;
  margin-top: 15px;
  cursor: pointer;
}



@media (max-width: 1050px) {
  .error {
    background-position: 120px 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .error {
    padding: 30px 35px;
  }
  .error-logo {
    margin-bottom: 70px;
  }
  .error-title {
    font-size: 60px;
    margin-bottom: 40px;
  }
  .error-cookies{
    font-size: 60px;
    margin-bottom: 40px;
  }
}

@media (max-width: 767.98px) {
  .error {
    padding: 20px 15px;
  }
  .error-logo {
    margin-bottom: 60px;
  }
  .error-title {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .error-cookies{
    font-size: 40px;
    margin-bottom: 20px;
  }
}