.nav-animation-enter {
  transform: translateX(100%);
}
.nav-animation-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.nav-animation-exit {
  transform: translateX(0);
}
.nav-animation-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}