@import '../../style/colors';
@import '../../style/mixin';

.guide {
  &-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #ECE8F0; }
    &:not(.active):hover {
      .guide-title span {
        color: $red-dark; }
      .guide-arrow {
        background-color: $red-dark;
        width: 57px;
        margin-right: 7px;
        &:after {
          border: 2px solid $red-dark;
          border-bottom: none;
          border-left: none; } } }
    &.active {
      .guide-title span {
        color: $red-dark; }
      .guide-arrow {
        display: none; } }
    &.next {
      padding: 10px 0 20px 0;
      margin-bottom: 10px; } }

  &-title {
    font-size: 30px;
    line-height: 35px;
    font-weight: normal;
    margin: 0;
    & span {
      color: $red;
      transition: all ease-in-out .2s; } }
  &-arrow {
    display: inline-block;
    width: 60px;
    height: 2px;
    background-color: $red;
    position: relative;
    margin-top: -1px;
    margin-right: 4px;
    transition: all .2s;
    transition: all ease-in-out .2s;
    &:after {
      content: '';
      height: 26px;
      width: 26px;
      border: 2px solid $red;
      border-bottom: none;
      border-left: none;
      display: block;
      position: absolute;
      right: 3px;
      top: -13px;
      transform: rotate(45deg);
      transition: all ease-in-out .2s; } }
  &-item {
    padding-top: 40px;
    display: flex;
    justify-content: space-between; }
  &-item__num {
    @include light;
    color: $red;
    font-size: 70px;
    line-height: 82px;
    letter-spacing: -2px; }
  &-item__text {
    font-size: 30px;
    line-height: 35px;
    width: 40%;
    box-shadow: inset 0px -1px 0px #ECE8F0;
    &.notification {
      width: 100%;
      font-size: 25px;
      line-height: 30px;
      box-shadow: none; } }
  &-item__img {
    display: inline-block;
    width: 300px;
    height: auto;
    padding: 10px;
    border: 2px solid #ECE8F0;
    border-radius: 37px;
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      width: 144px;
      height: 30px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0 0 10px 10px;
      z-index: 10; }
    & img {
      width: 100%;
      border-radius: 23px;
      object-fit: cover; } }

  &-next {
    background: $bgc;
    padding-top: 60px;
    &__block {
      width: 75%; } } }



@media (min-width: 992px) and (max-width: 1199.98px) {
  .guide {
    &-item__img {
      width: 270px; } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .guide {
    &-item__num {
      margin-right: 10px; }
    &-item__img {
      width: 250px; }
    &-item__text {
      font-size: 25px;
      line-height: 30px;
      padding-left: 20px; } } }

@media (max-width: 767.98px) {
  .guide {
    &-arrow {
      width: 40px;
      &:after {
        height: 16px;
        width: 16px;
        top: -8px; } }
    &-block:not(.active):hover {
      .guide-arrow {
        width: 37px; } }
    &-item {
      flex-direction: column;
      padding-top: 20px;
      &__num {
        order: 1; }
      &__img {
        order: 3;
        width: 100%;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto; }
      &__text {
        order: 2;
        width: 100%;
        padding: 0;
        box-shadow: none;
        margin-bottom: 20px; } }
    &-next {
      padding-top: 40px;
      &__block {
        width: 100%; } } } }

@media (max-width: 575.98px) {
  .guide {
    &-title {
      padding-right: 5px;
      width: calc( 100% - 40px ); } } }

@media (max-width: 319.98px) {
  .guide {
    &-title {
      font-size: 25px;
      line-height: 30px; } }
  .guide-item__text {
    font-size: 25px;
    line-height: 30px; } }
