@import '../../style/colors';
@import '../../style/mixin';

.bonus {
  background: $bgc;
  padding: 60px 0;
  &-title {
    @include light;
    font-size: 70px;
    line-height: 82px;
    letter-spacing: -2.1px;
    margin-bottom: 40px; }

  &-block {
    display: flex;
    align-items: flex-end;
    height: 190px;
    overflow-x: auto;
    margin-bottom: 40px;
    &::-webkit-scrollbar {
      width: 0;
      display: none; } }

  &-item {
    position: relative;
    width: 300px;
    height: calc( 100% - 5px );
    padding: 20px;
    background-color: #ffffff;
    border-radius: 16px;
    transition: all ease-in-out .2s;
    user-select: none;
    &:not(:last-child) {
      margin-right: 30px; }
    &:hover {
      transform: translateY(-5px);
      & span {
        color: $red-dark; } }
    &__title {
      font-size: 20px;
      line-height: 24px;
      color: $dark;
      width: 220px;
      & span {
        display: block;
        font-size: 16px;
        color: $red;
        transition: all ease-in-out .2s; } }
    &__icon {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 52px;
      height: 52px; } }
  &-main {
    max-width: 850px;
    &__block {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
      color: $dark;
      padding-bottom: 20px;
      box-shadow: inset 0px -1px 0px #ECE8F0;
      user-select: none;
      margin-bottom: 10px;
      &:hover {
        .bonus-main__arrow {
          background-color: $red-dark;
          width: 57px;
          margin-right: 33px; }
        .bonus-main__arrow::after {
          border: 2px solid $red-dark;
          border-bottom: none;
          border-left: none; }
        .bonus-main__title span {
          color: $red-dark; } } }
    &__title {
      max-width: 630px;
      font-size: 30px;
      margin-right: 30px;
      & span {
        font-size: 16px;
        color: $red;
        transition: all ease-in-out .2s;
        margin-left: 5px; } }
    &__arrow {
      display: inline-block;
      width: 60px;
      height: 2px;
      background-color: $red;
      position: relative;
      margin-top: -1px;
      margin-right: 30px;
      transition: all .2s;
      transition: all ease-in-out .2s;
      &::after {
        content: '';
        height: 26px;
        width: 26px;
        border: 2px solid $red;
        border-bottom: none;
        border-left: none;
        display: block;
        position: absolute;
        right: 3px;
        top: -13px;
        transform: rotate(45deg);
        transition: all ease-in-out .2s; } } } }


@media (max-width: 1199.98px) {
  .bonus-title {
    @include adaptive-font(70, 50); } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .bonus-main__title {
    margin-right: 0; } }

@media (max-width: 767.98px) {
  .bonus {
    padding: 40px 0;
    &-item {
      width: 240px; }
    &-main {
      &__block {
        flex-direction: column-reverse;
        &:hover {
          .bonus-main__arrow {
            margin-right: 0px; } } }
      &__arrow {
        margin-top: 15px;
        margin-bottom: 30px; } }
    &-item:not(:last-child) {
      margin-right: 20px; } } }

@media (max-width: 575.98px) {
  .bonus {
    &-main {
      &__arrow {
        width: 40px;
        &::after {
          height: 16px;
          width: 16px;
          top: -8px; } }
      &__block:hover .bonus-main__arrow {
        width: 37px; } }

    &-item:not(:last-child) {
      margin-right: 15px; } } }
