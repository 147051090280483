@mixin reg {
  font-family: 'Avenir Next Cyr Regular', sans-serif, system-ui;
  font-weight: 300;
}

@mixin light {
  font-family: 'Avenir Next Cyr Light', sans-serif, system-ui;
  font-weight: 100;
}

@mixin adaptive-font($pcSize, $mobSize) {
  $maxWidth: 1280;
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 320;
  font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
  line-height: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}) + 10px);
}

@mixin adaptive-font-pro($pcSize, $mobSize, $pcLineHeigth, $mobLineHeigth, $maxWidth) {
  $addSize: $pcSize - $mobSize;
  $addLineHeigth: $pcLineHeigth - $mobLineHeigth;
  $maxWidth: $maxWidth - 320;
  font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
  line-height: calc(#{$mobLineHeigth + px} + #{$addLineHeigth} * ((100vw - 320px) / #{$maxWidth}) + 10px);
}