@import '../../style/colors';

.login {
  background-color: $bgc;
  &-form {
    margin-bottom: 50px;
    &__block {
      max-width: 630px;
      width: 100%;
      height: 72px;
      position: relative;
      margin-bottom: 20px; }
    &__input {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      font-size: 20px;
      color: $dark;
      outline: none;
      border: 1px solid $grey;
      padding: 13px 25px;
      transition: all ease-in-out .3s;
      &::placeholder {
        color: #2F3059;
        opacity: .4; }
      &-active {
        padding: 42px 25px 11px; } }

    &__label {
      position: absolute;
      top: 12px;
      left: 25px;
      color: $dark;
      user-select: none;
      font-size: 20px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(13px);
      transition: all ease-in-out .3s;
      &-active {
        visibility: visible;
        opacity: .4;
        transform: translateY(0px); } }

    &__error {
      display: block;
      color: $red-dark;
      margin-top: -10px;
      margin-bottom: 10px;
      visibility: hidden;
      opacity: 0;
      transition: all ease-in-out .3s;
      &-active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px); } }
    &__forgot {
      display: flex;
      flex-direction: column;
      gap: 12px;
      & span {
        color: grey; } }
    &__button {
      width: 190px;
      height: 62px;
      background-color: $red;
      color: #ffffff;
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      border-radius: 7px;
      cursor: pointer;
      transition: all ease-in-out .3s;
      &:hover {
        background-color: $red-dark; } } }

  &-info {
    &__title {
      display: block;
      margin-bottom: 15px; }
    & .button-ios {
      margin-right: 10px; } } }

