@import '../../style/colors';
@import '../../style/mixin';

.page {
  padding: 60px 0;
  &-wrapper {
    display: flex; }
  &-content {
    flex-grow: 3;
    width: 75%;
    margin-right: 30px; }
  &-aside {
    flex-grow: 1;
    width: 25%; }
  &-contact {
    position: sticky;
    top: 20px;
    width: 190px;
    padding-left: 10px;
    &__text {
      display: block;
      line-height: 20px;
      margin-bottom: 25px; }
    &__mail {
      display: block;
      color: $red;
      margin-bottom: 10px; }
    &__phone {
      display: block;
      color: $dark; } } }


@media (max-width: 991.98px) {
  .page {
    padding: 40px 0;
    &-wrapper {
      flex-direction: column;
      &__reverse {
        .page-content {
          order: 1; } } }
    &-contact {
      padding: 0;
      width: 100%; }
    &-content, &-aside {
      width: 100%;
      margin-right: 0px; }
    &-aside {
      margin-bottom: 30px; } } }


@media (max-width: 767.98px) {
  .page {
    padding: 20px 0;
    &-wrapper {
      flex-direction: column; }
    &-contact {
      padding: 0;
      width: 100%; }
    &-content, &-aside {
      width: 100%;
      margin-right: 0px; }
    &-content {
      margin-bottom: 30px; } } }


.modal {
  &-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    animation-name: modal-video;
    transition: opacity .3s ease-out;
    &-body {
      max-width: 960px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      box-sizing: border-box; }
    &-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; }
    &-movie-wrap {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;
      background-color: #333;
      animation-timing-function: ease-out;
      animation-duration: .3s;
      animation-name: modal-video-inner;
      transform: translate(0, 0);
      transition: transform .3s ease-out;
      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    &-close-btn {
      position: absolute;
      z-index: 2;
      top: -45px;
      right: 0px;
      display: inline-block;
      width: 35px;
      height: 35px;
      overflow: hidden;
      border: none;
      background: transparent;
      &:before, &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #fff;
        border-radius: 5px;
        margin-top: -6px; }
      &:before {
        transform: rotate(45deg); }
      &:after {
        transform: rotate(-45deg); } } } }


@media (orientation: landscape) {
  .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }
