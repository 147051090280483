@import '../../../../style/colors';
@import '../../../../style/mixin';

.bonuses {
  &-title {
    @include light;
    font-size: 100px;
    line-height: 110px;
    letter-spacing: -3px;
    margin: 100px 0;
    & span {
      @include light;
      color: $red; } }
  &-item {
    &s {
      display: flex;
      flex-direction: column; }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: $bgc;
    height: 140px;
    border-radius: 24px;
    transition: all ease-in-out .2s;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 16px; }
    &:hover .bonuses-item__arrow svg path {
      stroke: $red-dark; }
    &__header {
      width: 100%;
      position: relative;
      padding: 0 40px;
      user-select: none;
      cursor: pointer;
      &-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        height: 100%; }
      &-title {
        display: flex;
        align-items: center;
        & h3 {
          font-weight: 400;
          font-size: 50px;
          line-height: 61px;
          margin: 40px 0; } }
      &-img {
        width: 65px;
        height: 42px;
        margin-right: 32px; }
      &-info {
        display: block;
        width: 530px;
        font-weight: 400;
        font-size: 30px;
        line-height: 41px;
        opacity: 0;
        max-height: 0;
        visibility: hidden;
        transition: all ease .2s;
        cursor: auto;
        color: transparent;
        & span {
          color: $red;
          color: transparent; }
        &__button {
          cursor: pointer;
          font-size: 16px;
          & a {
            color: $red;
            color: transparent; } } }
      &-arrow {
        right: 40px;
        top: 70px; } }
    &__body {
      width: 100%;
      padding: 70px 40px 40px;
      opacity: 0;
      max-height: 0;
      visibility: hidden;
      transition: all ease .3s;
      &-title {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;
        padding-bottom: 10px;
        box-shadow: 0px 1px 0px #ece8f0;
        margin: 0 0 20px; }
      &-cars {
        margin-bottom: 40px; }
      &-info {
        width: 300px; }
      &-description {
        // width: 50%
        font-size: 20px;
        line-height: 27px;
        margin-top: 50px; } }

    &__cars {
      &-item {
        &s {
          display: flex;
          flex-wrap: wrap; }
        &.empty {
          background: transparent; }
        display: flex;
        flex-direction: column;
        min-width: 240px;
        flex-basis: calc(25% - 8px);
        height: 197px;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 20px;
        overflow: hidden;
        margin-right: 10px;
        margin-bottom: 10px; }
      &-img {
        height: 60px;
        margin: 17px auto 37px auto; }
      &-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 5px; }
      &-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #9899AE; } }
    &__info {
      &-text {
        display: flex;
        justify-content: space-between;
        & span {
          font-weight: 400;
          font-size: 30px;
          line-height: 37px;
          & span {
            color: $red; } } }
      &-info {
        width: 17px;
        height: 17px; }
      &-tooltip {
        width: 330px; } }
    &.active {
      border-radius: 16px;
      height: auto;
      & .bonuses-item {
        &__header-info {
          opacity: 1;
          max-height: 300px;
          visibility: visible; }
        &__header {
          &-info {
            color: $dark;
            & span, &__button a {
              color: $red; } }
          &-arrow {
            transform: rotate(180deg);
            & svg path {
              stroke: $red-dark; } } }
        &__body {
          opacity: 1;
          max-height: 1030px;
          visibility: visible; } } } }

  & .page {
    padding: 100px 0; }
  &-aside {
    width: 190px;
    margin-left: auto;
    position: sticky;
    top: 20px;
    &__img {
      display: block;
      width: 32px;
      height: 32px;
      margin-bottom: 20px; }
    &__text {
      color: $red;
      border-bottom: 1px solid rgba(47, 48, 89, 0.16);
      & span {
        display: block;
        margin-bottom: 20px; } } }

  &-details {
    width: 630px;
    &__title {
      font-size: 30px;
      line-height: 37px;
      margin: 0 0 28px; }
    &__subtitle {
      display: block;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 16px;
      & span {
        display: block; } }

    &__table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 40px;
      & thead .bonuses-details__table-row {
        border-bottom: 1px solid rgba(47, 48, 89, 0.16); }
      &-row {
        height: 53px;
        border-bottom: 1px solid rgba(47, 48, 89, 0.16);
        &:last-child {
          border-bottom: none; }
        & th {
          text-align: inherit;
          vertical-align: bottom;
          font-size: 12px;
          line-height: 23px;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          & div {
            display: flex;
            flex-direction: column;
            align-items: center;
            & img {
              height: 16px;
              margin-bottom: 5px; }
            & span span {
              color: $red; } }
          &:nth-child(1) {
            width: 192px; }
          &:nth-child(1n+2) {
            width: calc((100% - 192px)/3); } }
        & td {
          font-size: 30px;
          line-height: 37px; } }
      &-red {
        text-align: center;
        color: $red; }
      &-mob {
        display: none;
        margin-bottom: 50px;
        & .table-mob {
          &__item {
            margin-bottom: 40px;
            &s {
              display: flex;
              flex-direction: column; } }
          &__title {
            text-align: center;
            border-bottom: 1px solid rgba(47, 48, 89, 0.16);
            & h4 {
              font-size: 16px;
              line-height: 23px;
              margin: 0 0 5px; }
            & span {
              display: block;
              font-size: 30px;
              line-height: 37px;
              margin-bottom: 10px; } }
          &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(47, 48, 89, 0.16); }
            &-title {
              & img {
                width: 26px;
                height: 17px;
                margin-right: 10px; }
              & span span {
                color: $red; } }
            &-total {
              color: $red; } } } } }

    &__description {
      font-size: 20px;
      line-height: 26px;
      & span {
        display: block;
        margin-bottom: 10px;
        & span {
          display: inline;
          color: $red; } } } } }




@media (min-width: 1200px) {
  .bonuses {
    &-item__cars-item {
      &:nth-child(4n+4) {
        margin-right: 0; } } } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .bonuses {
    &-title {
      font-size: 80px;
      line-height: 90px;
      margin: 80px 0; }
    &-item__cars-item {
      flex-basis: calc(33% - 4px);
      &:nth-child(3n+3) {
        margin-right: 0; } } } }

@media (max-width: 991.98px) {
  .bonuses-title {
    @include adaptive-font(70, 40); } }


@media (max-width: 991.98px) {
  .bonuses-item__header {
    &-wrapper {
      flex-direction: column; }
    &-info {
      width: auto; } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .bonuses {
    &-title {
      margin: 60px 0; }
    &-item {
      &__body-description {
 }        // width: 90%
      &__cars-item {
        flex-basis: calc(33% - 5px);
        &:nth-child(3n+3) {
          margin-right: 0; } } }
    & .page {
      padding: 100px 0 60px;
      &-wrapper {
        flex-direction: row-reverse; } } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .bonuses {
    & > .container {
      width: calc(100% - 60px); }
    & .page {
      & .container {
        width: 100%; } }
    &-details__table {
      width: calc(100% - 30px);
      &-row {
        & th:nth-child(1) {
          width: 150px; } } } } }

@media (min-width: 768px) and (max-width: 879.98px) {
  .bonuses {
    &-item {
      &__body {
        padding: 70px 20px 40px; }
      &__cars-item {
        min-width: auto;
        height: 215px; } }
    &-details {
      width: calc(100vw - 250px);
      padding-right: 20px; } } }


@media (max-width: 767.98px) {
  .bonuses {
    & .page .page-content {
      margin: 0; }
    &-title {
      margin: 20px 0px 50px; }
    &-item {
      height: 75px;
      &__header {
        padding: 0 30px;
        &-title h3 {
          font-size: 30px;
          line-height: 37px;
          margin: 20px 0; }
        &-info {
          font-size: 20px;
          line-height: 27px; }
        &-arrow {
          top: 35px;
          right: 30px; } }
      &__body {
        padding: 40px 15px 20px;
        &-cars {
          margin-bottom: 60px; }
        &-description {
          font-size: 14px;
          line-height: 19px;
          width: 100%; } }
      &__cars-item {
        min-width: 260px;
        &s {
          flex-wrap: nowrap;
          overflow: auto;
          &::-webkit-scrollbar {
            height: 7px; }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #cacaca;
            border-radius: 10px; }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px #a7a7a7; } }
        &:last-child {
          margin-right: 0; } }
      &__info-text span {
        font-size: 20px;
        line-height: 25px; } }
    & .page {
      padding: 50px 0; }
    &-aside {
      width: 100%;
      margin-bottom: 20px; }
    &-details {
      width: 100%;
      &__title {
        margin-bottom: 16px; }
      &__subtitle {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px; }
      &__description {
        font-size: 16px;
        line-height: 20px; } } } }


@media (max-width: 575.98px) {
  .bonuses {
    & > .container {
      width: 100%;
      margin: 0; }
    &-title {
      margin: 20px 15px 50px; }
    &-item {
      &__header {
        padding: 0 15px; }
      &__body {
        padding: 40px 0 20px;
        &-title {
          margin-left: 15px;
          margin-right: 15px; }
        &-info {
          width: 100%;
          padding: 0 15px;
          & .bonuses-item__body-title {
            margin-left: 0;
            margin-right: 0; } }
        &-description {
          margin-top: 30px;
          padding: 0 15px; } }
      &__cars-item {
        &:first-child {
          margin-left: 10px; }
        &:last-child {
          margin-right: 10px; } }
      &__info-text span {
        font-size: 20px;
        line-height: 25px; }
      &__info-info .bonuses-item__info-tooltip {
        transform: translateX(0) translateY(calc(-100% - 10px));
        right: 0;
        width: calc(100vw - 30px); }
      &__info-info:hover .bonuses-item__info-tooltip {
        transform: translateX(0) translateY(calc(-100% - 15px)); } }

    &-details {
      &__table-desk {
        display: none; }
      &__table-mob {
        display: block; }
      &__subtitle {
        margin-bottom: 40px; } } } }

@media (max-width: 369.98px) {
  .bonuses-item__header-title h3 {
    font-size: 28px; } }

@media (max-width: 339.98px) {
  .bonuses-item__header-title h3 {
    font-size: 24px; } }
