@import '../../../../style/colors';
@import '../../../../style/mixin';

.slider {
  padding: 66px 0 60px 0;
  box-shadow: inset 0px -1px 0px #ECE8F0;
  &-header {
    overflow: hidden;
    padding-top: 12px;
    margin-bottom: 75px;
    &__item {
      cursor: pointer;
      width: calc(1290px * 0.35);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: .8s;
      &s {
        display: flex;
        flex-wrap: nowrap;
        width: 3000px; }
      &:hover {
        .slider-header__title {
          color: $red-dark; }
        .slider-header__arrow {
          background-color: $red-dark;
          flex-grow: 0.96;
          &::after {
            border-color: $red-dark; } } } }

    &__item.hidden {
      opacity: 0;
      visibility: hidden; }
    &__item.active {
      width: calc(1290px * 0.65); }
    &__block {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }
    &__title {
      @include light;
      font-size: 70px;
      display: inline-block;
      letter-spacing: -2px;
      transition: all .2s;
      margin-right: 39px; }
    &__arrow {
      display: inline-block;
      flex-grow: 1;
      height: 3px;
      background-color: $red;
      position: relative;
      margin-top: -1px;
      margin-right: 30px;
      transition: all .2s;
      &::after {
        content: '';
        height: 67px;
        width: 67px;
        border: 3px solid $red;
        border-bottom: none;
        border-left: none;
        display: block;
        position: absolute;
        right: 13px;
        top: -34px;
        transform: rotate(45deg); } }
    &__subtitle {
      width: 170px;
      font-size: 16px;
      line-height: 20px; } }

  &-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      min-height: 680px;
      padding-top: 6px; }
    &__item {
      transition: all .2s; }
    &__title {
      @include light;
      font-size: 48px;
      max-width: 700px;
      line-height: 60px;
      letter-spacing: -2px;
      user-select: none;
      margin-bottom: 71px; }
    &__button {
      overflow: hidden;
      &:first-child {
        margin-right: 5px; } }
    &__phone {
      display: inline-block;
      width: 326px;
      height: auto;
      padding: 10px;
      border: 2px solid #ECE8F0;
      border-radius: 37px;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        width: 144px;
        height: 30px;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0 0 10px 10px;
        z-index: 10; }
      & img {
        display: block;
        width: 100%;
        border-radius: 23px;
        object-fit: cover; } } } }


@media (max-width: 991.98px) {
  .slider-header__title {
    @include adaptive-font(70, 40); } }

@media (max-width: 612.98px) {
  .slider-header {
    padding-top: 16px; } }

@media (max-width: 457.98px) {
  .slider-header {
    padding-top: 21px; } }

@media (max-width: 319.98px) {
  .slider-main__button {
    width: 123px;
    height: 41px; } }

@media (max-width: 575.98px) {
  .slider {
    &-header {
      &__item, &__item.active {
        width: calc(100vw - 30px); }
      &__subtitle {
        width: 90%; } }
    &-main {
      &__title {
        width: calc(100vw - 30px); }

      &__phone {
        width: 66%;
        padding: 8px;
        border-radius: 30px;
        & img {
          border-radius: 21px; }
        &::before {
          width: 50%;
          height: 4%;
          min-height: 19px; } }

      &__button {
        &:first-child {
          margin-right: 0px; } } } } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .slider {
    &-header {
      &__item, &__item.active {
        width: 540px; } }
    &-main {
      &__title {
        max-width: 540px; } } } }

@media (max-width: 767.98px) {
  .slider {
    padding: 40px 0;
    &-header {
      margin-bottom: 60px;
      &__arrow {
        margin-right: 0;
        &::after {
          height: 30px;
          width: 30px;
          right: 5px;
          top: -15px; } }

      &__item.active .slider-header__arrow {
        margin-right: 2px; }
      &__title {
        margin-right: 20px;
        @include adaptive-font(100, 50); } }
    &-main {
      align-items: center;
      flex-direction: column;
      &__content {
        min-height: auto;
        margin-bottom: 40px; }
      &__title {
        margin-bottom: 40px;
        @include adaptive-font(70, 40); } } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .slider {
    &-header {
      &__arrow {
        margin-right: 0; }
      &__item.active .slider-header__arrow {
        margin-right: 20px; }
      &__title {
        margin-right: 20px; }
      &__item {
        width: calc(720px * 0.4); }
      &__item.active {
        width: calc(720px * 0.6); } }
    &-main {
      &__title {
        max-width: 390px; } } } }

@media (min-width: 960px) and (max-width: 991.98px) {
  .slider {
    &-header {
      &__arrow {
        margin-right: 0; }
      &__item.active .slider-header__arrow {
        margin-right: 20px; }
      &__title {
        margin-right: 20px; }
      &__item {
        width: calc(850px * 0.4); }
      &__item.active {
        width: calc(850px * 0.6); } }
    &-main {
      &__title {
        max-width: 500px; } } } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .slider {
    &-header {
      &__item {
        width: calc(960px * 0.38); }
      &__item.active {
        width: calc(960px * 0.62); } }
    &-main {
      &__title {
        max-width: 600px; } } } }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .slider {
    &-header {
      &__item {
        width: calc(1140px * 0.38); }
      &__item.active {
        width: calc(1140px * 0.62); } }
    &-main {
      justify-content: flex-start; } } }

@media (min-width: 1400px) {
  .slider {
    &-main {
      &__phone {
        margin-right: 163px; } } } }

@media (max-width: 299.98px) {
  .slider-main__title {
    font-size: 35px;
    line-height: 40px; } }
