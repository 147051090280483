@import '../../../../style/colors';
@import '../../../../style/mixin';

.slider-business {
  box-shadow: none;
  .slider-main__content {
    min-height: 417px; }
  .slider-main__title {
    padding-right: 10px; }
  .slider-main__phone {
    width: 495px;
    margin-right: 0;
    &::before {
      display: none; } }

  .slider-header {
    &__item {
      width: calc(1290px * 0.45); }
    &__item.active {
      width: calc(1290px * 0.55); } } }

@media (max-width: 1399.98px) {
  .slider-business {
    .slider-main__content {
      min-height: 398px; } } }

@media (max-width: 1199.98px) {
  .slider-business {
    .slider-main__title {
      margin-bottom: 20px; }
    .slider-main__content {
      min-height: 367px; } } }

@media (max-width: 991.98px) {
  .slider-business {
    .slider-main__title {
      font-size: 45px;
      line-height: 45px; }
    .slider-main__phone {
      width: 420px; }
    .slider-main__content {
      min-height: 328px; } } }

@media (max-width: 959.98px) {
  .slider-business {
    .slider-main__title {
      font-size: 40px;
      line-height: 40px; }
    .slider-main__phone {
      width: 420px; }
    .slider-main__content {
      min-height: 316px; } } }

@media (max-width: 767.98px) {
  .slider-business {
    .slider-main__content {
      min-height: auto;
      margin-bottom: 30px; }
    .slider-main__title {
      padding-right: 0;
      margin-bottom: 40px; }
    .slider-main__phone {
      width: 100%; } } }

@media (max-width: 299.98px) {
  .slider-business {
    .slider-main__title {
      font-size: 35px;
      line-height: 40px; } } }







@media (min-width: 1200px) and (max-width: 1399.98px) {
  .slider-business .slider {
    &-header {
      &__item {
        width: calc(1140px * 0.38); }
      &__item.active {
        width: calc(1140px * 0.62); } }
    &-main {
      justify-content: flex-start; } } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .slider-business .slider {
    &-header {
      &__item {
        width: calc(960px * 0.38); }
      &__item.active {
        width: calc(960px * 0.62); } }
    &-main {
      &__title {
        max-width: 600px; } } } }

@media (min-width: 960px) and (max-width: 991.98px) {
  .slider-business .slider {
    &-header {
      &__arrow {
        margin-right: 0; }
      &__item.active .slider-header__arrow {
        margin-right: 20px; }
      &__title {
        margin-right: 20px; }
      &__item {
        width: calc(850px * 0.4); }
      &__item.active {
        width: calc(850px * 0.6); } }
    &-main {
      &__title {
        max-width: 500px; } } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .slider-business .slider {
    &-header {
      &__arrow {
        margin-right: 0; }
      &__item.active .slider-header__arrow {
        margin-right: 20px; }
      &__title {
        margin-right: 20px; }
      &__item {
        width: calc(720px * 0.4); }
      &__item.active {
        width: calc(720px * 0.6); } }
    &-main {
      &__title {
        max-width: 390px; } } } }

@media (max-width: 767.98px) {
  .slider-business .slider {
    padding: 40px 0;
    &-header {
      margin-bottom: 60px;
      &__arrow {
        margin-right: 0;
        &::after {
          height: 30px;
          width: 30px;
          right: 5px;
          top: -15px; } }

      &__item.active .slider-header__arrow {
        margin-right: 2px; }
      &__title {
        margin-right: 20px;
        @include adaptive-font(100, 50); } }
    &-main {
      align-items: center;
      flex-direction: column;
      &__content {
        min-height: auto;
        margin-bottom: 40px; }
      &__title {
        margin-bottom: 40px;
        @include adaptive-font(70, 40); } } } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .slider-business .slider {
    &-header {
      &__item, &__item.active {
        width: 540px; } }
    &-main {
      &__title {
        max-width: 540px; } } } }

@media (max-width: 575.98px) {
  .slider-business .slider {
    &-header {
      &__item, &__item.active {
        width: calc(100vw - 30px); }
      &__subtitle {
        width: 90%; } }
    &-main {
      &__title {
        width: calc(100vw - 30px); }

      &__phone {
        width: 66%;
        padding: 8px;
        border-radius: 30px;
        & img {
          border-radius: 21px; }
        &::before {
          width: 50%;
          height: 4%;
          min-height: 19px; } }

      &__button {
        &:first-child {
          margin-right: 0px; } } } } }

@media (max-width: 439.98px) {
  .slider-business {
    & .slider-header__title, & .slider-main__title {
      font-size: 40px;
      line-height: 50px; }
    // width: 123px
 } }    // height: 41px

@media (max-width: 319.98px) {
  .slider-business .slider-main__button {
    width: 123px;
    height: 41px; } }

@media (max-width: 309.98px) {
  .slider-business {
    & .slider-header__title, & .slider-main__title {
      font-size: 35px;
      line-height: 45px; } } }

@media (max-width: 457.98px) {
  .slider-business .slider-header {
    padding-top: 21px; } }
