@import '../../../../style/colors';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  background-color: $dark;
  transition: all ease-in-out .2s;
  &-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    flex-direction: column;
    max-width: 1070px;
    width: 90%;
    height: 90%;
    max-height: 645px;
    min-height: 300px;
    overflow: hidden;
    background-color: $red;
    color: #ffffff;
    position: relative;
    padding: 50px;
    & a {
      color: #ffffff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        .popup-arrow {
          width: 57px;
          margin-right: 7px; } }
      &:active {
        color: #ffffff; } } }
  &-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    & svg {
      width: 36px;
      height: 36px; }
    & svg path {
      fill: #ffffff; } }

  &-title {
    font-size: 90px; }
  &-subtitle {
    display: block;
    width: 70%;
    font-size: 30px;
    font-weight: 400; }


  &-arrow {
    display: inline-block;
    width: 60px;
    height: 2px;
    background-color: #ffffff;
    position: relative;
    margin-top: -1px;
    margin-right: 4px;
    transition: all .2s;
    transition: all ease-in-out .2s;
    &:after {
      content: '';
      height: 26px;
      width: 26px;
      border: 2px solid #ffffff;
      border-bottom: none;
      border-left: none;
      display: block;
      position: absolute;
      right: 3px;
      top: -13px;
      transform: rotate(45deg);
      transition: all ease-in-out .2s; } } }

@media (max-width: 910.98px) {
  .popup {
    &-wrapper {
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      padding: 0 20px;
      & a {
        flex-direction: column-reverse;
        align-items: start; } }
    &-title {
      font-size: 35px; }
    &-subtitle {
      font-size: 25px;
      margin-top: 25px; } } }
