* {
  @include reg;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }

body {
  color: $dark; }

a, a:active {
  text-decoration: none;
  color: #2F3059; }

img {
  pointer-events: none;
  user-select: none; }

button {
  user-select: none;
  border: none; }

.container {
  width: 1290px;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 575.98px) {
  .container {
    width: auto;
    margin-left: 15px;
    margin-right: 15px; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .container {
    width: 540px; } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .container {
    width: 720px; } }

@media (min-width: 960px) and (max-width: 991.98px) {
  .container {
    width: 850px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .container {
    width: 960px; } }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .container {
    width: 1140px; } }



// @media (max-width: 575.98px)
// /* extra small |  auto   |  mob */

// @media (min-width: 576px) and (max-width: 767.98px)
// /* small       |  540px  |  mob */

// @media (min-width: 768px) and (max-width: 959.98px)
// /* medium      |  720px  |  tb  */
// @media (min-width: 960px) and (max-width: 991.98px)
// /* medium      |  850px  |  tb figma */

// @media (min-width: 992px) and (max-width: 1199.98px)
// /* large       |  960px  |  lap */

// @media (min-width: 1200px)
// /* extra large |  1140px |  pc  */
