@import '../../style/colors';
@import '../../style/mixin';


.rates {
  &-promo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 131px;
    background-color: $bgc;
    background-position: right top;
    background-repeat: no-repeat;
    border-radius: 16px;
    opacity: 1;
    max-height: 200px;
    transition: all ease-in-out .2s;
    &.disable {
      opacity: 0;
      max-height: 0;
      padding: 0;
      margin: 0 !important; }
    &__close {
      color: $red;
      cursor: pointer;
      margin: 15px 15px 0 0;
      & svg {
        width: 24px;
        height: 24px;
        & path {
          fill: $red; } } }
    &__text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      font-size: 30px;
      line-height: 36.75px;
      margin-left: 25px;
      & span {
        font-size: 16px;
        line-height: 20px; } }
    &.mob {
      display: none;
      margin-bottom: 40px; } }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    padding: 0 15px;
    font-size: 16px;
    line-height: 22px;
    & > div:nth-child(1) {
      width: 50%; }
    & > div:nth-child(2), div:nth-child(3) {
      width: 25%; }
    & > div:nth-child(3) {
      text-align: center; } }

  &-item {
    font-size: 30px;
    line-height: 32px;
    transition: all ease-in-out .2s;
    box-shadow: inset 0px 1px 0px $grey;
    &__body {
      opacity: 0;
      max-height: 0;
      padding: 0 15px;
      overflow: hidden;
      transition: all ease-in-out .2s, max-height linear .2s, opacity linear .1s;
      &:hover {
        overflow: inherit; } }
    &__body > div {
      padding: 20px 0 0;
      box-shadow: inset 0px 1px 0px #ECE8F0; }
    &.active {
      background-color: $bgc;
      border-radius: 16px;
      box-shadow: none;
      margin-bottom: 10px;
      & .rates-item__title {
        color: $red-dark; }
      & .rates-item__price_number {
          color: $red-dark; }
      & .rates-item__arrow {
        transform: rotate(180deg);
        & svg path {
          stroke: $red-dark; } }
      & .rates-item__body {
        opacity: 1;
        max-height: 300px; }
      & + .rates-item {
        box-shadow: none; } }


    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 142px;
      padding: 0 15px;
      position: relative;
      user-select: none;
      cursor: pointer;
      & > div:nth-child(1) {
        width: 50%; }
      & > div:nth-child(2), div:nth-child(3) {
        width: 25%; }
      & > div:nth-child(3) {
        text-align: center; }
      &:hover {
        .rates-item__title {
          color: $red-dark; }
        .rates-item__price_number {
          color: $red-dark; }
        .rates-item__arrow svg path {
          stroke: $red-dark; } } }

    &__arrow {
      display: flex;
      position: absolute;
      right: 15px;
      &, & svg, & path {
        transition: all ease-in-out .2s; } }
    &__title {
      display: flex;
      align-items: center;
      text-wrap: nowrap;
      & h2 {
        font-size: 30px;
        line-height: 30px;
        margin: 0;
        font-weight: 300;
        margin-right: 60px;
        transition: all ease-in-out .2s;
        width: 130px;
        & span {
          display: block;
          color: #9899AE;
          font-size: 16px;
          line-height: 19px; } } }
    &__subtitle {
      display: none; }
    &__img {
      height: auto;
      max-height: 78px;
      max-width: 180px;
      width: auto;
      margin-right: 10px; }



    &__price {
      &_count {
        display: flex;
        align-items: center; }
      &_number {
        @include light;
        font-size: 70px;
        color: $red;
        letter-spacing: -2px;
        margin-right: 10px;
        transition: all ease-in-out .2s; }
      &_units {
        display: inline-block;
        transform: translateY(6px);
        &-euro {
          display: block;
          width: 56px;
          padding-bottom: 3px;
          border-bottom: 1px solid rgba(47, 48, 89, .6); }
        &-euro, &-min {
          text-align: left; } } }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;

      &_2 {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between; } } }


  &-body {
    &__img {
      display: none; }
    &__title {
      position: relative;
      font-size: 20px;
      line-height: 23px;
      margin: 0 0 10px;
      font-weight: 400; }
    &__item {
      width: 20%;
      color: #9899ae;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 20px;
      &.small {
 } }        // width: 12%
    &__price {
      & span {
        font-size: 16px;
        color: #000000; } }
    &__subtitle {
      position: relative;
      & .rates-body__info {
        position: absolute; } }

    &__info {
      display: inline-block;
      width: 14px;
      height: 14px;
      transform: translateY(2px);
      margin-left: 4px;
      position: relative;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33331 3.66665H7.66665V4.99998H6.33331V3.66665ZM6.33331 6.33331H7.66665V10.3333H6.33331V6.33331ZM6.99998 0.333313C3.31998 0.333313 0.333313 3.31998 0.333313 6.99998C0.333313 10.68 3.31998 13.6666 6.99998 13.6666C10.68 13.6666 13.6666 10.68 13.6666 6.99998C13.6666 3.31998 10.68 0.333313 6.99998 0.333313ZM6.99998 12.3333C4.05998 12.3333 1.66665 9.93998 1.66665 6.99998C1.66665 4.05998 4.05998 1.66665 6.99998 1.66665C9.93998 1.66665 12.3333 4.05998 12.3333 6.99998C12.3333 9.93998 9.93998 12.3333 6.99998 12.3333Z' fill='%239899AE'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      transition: all ease-in-out .2s;
      position: relative;
      z-index: 10; }
    &__tooltip {
      visibility: hidden;
      position: absolute;
      background-color: #fff;
      border-radius: 8px;
      padding: 15px 20px;
      box-shadow: 0 4px 16px rgba(47, 48, 89, 0.24);
      color: $dark;
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      min-width: 120px;
      transform: translateX(-50%) translateY(-100%);
      transition: transform ease-in-out .2s;
      z-index: 1;
      & p {
        width: 340px;
        margin: 0; }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;
        bottom: -15px;
        position: absolute; }
      &-mob {
        display: none; }
      &.big {
        width: 200px; } }
    &__info:hover {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33331 3.66665H7.66665V4.99998H6.33331V3.66665ZM6.33331 6.33331H7.66665V10.3333H6.33331V6.33331ZM6.99998 0.333313C3.31998 0.333313 0.333313 3.31998 0.333313 6.99998C0.333313 10.68 3.31998 13.6666 6.99998 13.6666C10.68 13.6666 13.6666 10.68 13.6666 6.99998C13.6666 3.31998 10.68 0.333313 6.99998 0.333313Z' fill='%239899AE'/%3E%3C/svg%3E");
      & .rates-body__tooltip {
        visibility: visible;
        transform: translateX(-50%) translateY(calc( -100% - 5px ));
        &-mob_hide {
          transform: translateX(-50%) translateY(calc( -100% - 10px )); } } } } }


@media (min-width: 992px) and (max-width: 1199.98px) {
  .rates {
    &-promo {
      background-position: 175% top; }
    &-item {
      font-size: 28px;
      line-height: 30px; }
    &-item__price_number {
      font-size: 68px; }
    &-header, &-item__header {
      & > div:nth-child(1) {
        width: 50%; }
      & > div:nth-child(2), div:nth-child(3) {
        width: 25%; } }
    &-item__title h2 {
      margin-right: 20px; }
    &-item__img {
      max-height: 75px;
      max-width: 160px; } } }

@media (max-width: 991.98px) {
  .page-wrapper {
    flex-direction: column;
    & > div {
      width: 100%; }
    .page-content {
      order: 1; } }
  .rates-promo {
    &.desk {
      display: none; }
    &.mob {
      display: flex; } } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .rates-body__info {
    .rates-body__tooltip.right {
      transform: translateX(calc( -100% + 40px)) translateY(calc( -100% - 5px));
      &::after {
        width: 30%;
        right: 0; } } } }

@media (min-width: 768px) and (max-width: 959.98px) {
  .rates {
    &-promo.mob {
      background-position: 160% top; }
    &-item {
      font-size: 28px;
      line-height: 30px; }
    &-item__price_number {
      font-size: 68px; }
    &-header, &-item__header {
      & > div:nth-child(1) {
        width: 50%; }
      & > div:nth-child(2), div:nth-child(3) {
        width: 25%; } }
    &-item__title h2 {
      margin-right: 20px; }
    &-item__img {
      max-height: 75px; } } }

@media (max-width: 767.98px) {
  .rates {
    &-promo.mob {
      height: 166px;
      background-position: 15px 70%;
      & .rates-promo {
        &__text {
          justify-content: flex-start;
          margin: 15px 0 0 15px; } } }
    &-item__header {
      justify-content: flex-end;
      & > div:nth-child(1) {
        position: absolute;
        left: 15px;
        width: 100%; }
      & > div:nth-child(2), div:nth-child(3) {
        width: 25%; } }
    &-header {
      display: none; }

    &-item {
      &.active .rates-item__body {
        max-height: 720px; }
      &__header {
        padding: 20px 15px;
        height: 127px; }
      &__title {
        align-self: flex-start; }
      &__arrow {
        position: absolute;
        align-self: flex-start; }
      &__img {
        display: none; }
      &__subtitle {
        display: block; }
      &__price, &__daily {
        flex-direction: column;
        align-self: flex-end; }
      &__price, &__daily, &__price_number {
        font-size: 16px;
        line-height: 19px; }
      &__price {
        &_number {
          margin-right: 5px; }
        &_units {
          display: flex;
          transform: translateY(0);
          &-euro {
            display: inline-block;
            border: none;
            width: auto;
            padding: 0;
            &::after {
              content: '/'; } } } }
      &__body {
        padding-bottom: 10px; }
      & div.rates-item__daily {
        text-align: inherit; }

      &__body > div {
        box-shadow: none; }
      &__wrapper {
        flex-direction: column;
        flex-wrap: nowrap; } }
    &-body {
      &__img {
        display: block;
        max-width: 360px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
      &__item {
        display: flex;
        justify-content: space-between;
        padding: 8px 1px;
        width: 100%;
        margin-bottom: 0;
        &:not(:last-child) {
          box-shadow: inset 0px -1px 0px #ece8f0; }
        &.small {
          width: 100% !important; } }
      &__price {
        width: 80px; }

      &__tooltip-mob {
        display: block;
        transform: translateX(0) translateY(calc(-100% - 25px));
        & p {
          width: calc( 100% - 30px ); }
        &_hide {
          display: none; } } } }

  .rates-body__title .rates-body__info:hover + .rates-body__tooltip-mob {
      visibility: visible;
      transform: translateX(0) translateY(calc(-100% - 30px)); } }

@media (max-width: 399.98px) {
  .rates {
    &-item__header {
      & > div:nth-child(2), div:nth-child(3) {
        width: 30%; } } } }

@media (max-width: 339.98px) {
  .rates {
    &-item__header {
      & > div:nth-child(2), div:nth-child(3) {
        width: 35%; } } } }

@media (max-width: 289.98px) {
  .rates {
    &-item__header {
      & > div:nth-child(2), div:nth-child(3) {
        width: 39%; } } } }






.rates-switch {
  position: sticky;
  top: 20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    font-size: 16px;
    line-height: 22px;
    box-shadow: inset 0px -1px 0px $grey;
    margin-bottom: 20px; }
  &__switcher {
    display: flex;
    align-items: center;
    user-select: none;
    margin-bottom: 20px; }
  &__price {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 19px;
    &_title {
      margin-bottom: 5px; }
    &_number {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 20px; }
    &_subtitle {
      margin-bottom: 40px; } }
  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &_item {
      display: flex;
      justify-content: space-between;
      padding: 8px 1px;
      color: #9899ae;
      &:not(:last-child) {
        box-shadow: inset 0px -1px 0px $grey; }
      & span {
        color: $dark;
        min-width: 58px; } } }
  &__promo {
    font-weight: 400;
    font-size: 30px;
    line-height: 36.75px;
    margin-bottom: 40px;
    & span {
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px; } }
  &__coin {
    &_title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px; }
    &_subtitle {
      font-size: 16px;
      line-height: 19px; } } }



@media (min-width: 768px) and (max-width: 991.98px) {
  .rates-switch {
    position: relative;
    top: 0;
    &__header, &__switcher, &__price {
      justify-content: center; }
    &__price div {
      margin-left: auto;
      margin-right: auto; }
    &__info {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;
      &_item {
        width: calc( 50% - 10px );
        &:nth-last-child(2) {
          box-shadow: none; } } }
    &__promo {
      text-align: center; }
    &__coin {
      text-align: center; } } }

@media (max-width: 767.98px) {
  .rates-switch {
    position: relative;
    top: 0;
    &__header, &__switcher, &__price {
      justify-content: center; }
    &__price div {
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
    &__info {
      margin-bottom: 40px; }
    &__promo {
      text-align: center; }
    &__coin {
      margin-bottom: 10px; } } }

@media (max-width: 350.98px) {
  .rates-switch__switcher {
    justify-content: space-between; } }
