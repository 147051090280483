@import '../../style/colors';

.button {
  display: inline-block;
  width: 190px;
  height: 62px;
  cursor: pointer;
  transition: all ease-in-out .3s;
  position: relative;
  font-size: 20px;
  line-height: 23px;
  border-radius: 7px;
  background-color: #ffffff;

  &:first-child {
    margin-right: 10px; }
  &:hover {
    opacity: .7; }
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-weight: 900; }
  &-img {
    border-radius: 7px;
    width: 100%; }
  &-app {
    background-color: #2f3059;
    width: 162px;
    height: 54px; }

  &-dark {
    a {
      color: #ffffff; }
    background-color: $dark;
    border: 2px solid rgba(#FFFFFF, .1); } }


@media (max-width: 575.98px) {
  .button {
    width: 180px;
    &-app {
      width: calc((100% - 10px)/2);
      max-width: 162px;
      min-width: 118px; } } }

@media (max-width: 393.98px) {
  .button {
    width: 140px;
    height: 52px;
    font-size: 16px;
    line-height: 19px; } }

@media (max-width: 320.98px) {
  .button {
    &-app {
      height: 48px; } } }
@media (max-width: 319.98px) {
  .button {
    width: 120px; } }
@media (max-width: 290.98px) {
  .button {
    &-app {
      height: 43px; } } }
