@import '../../style/colors';
@import '../../style/mixin';

.mobileArticle {
  width: 100%;
  height: 100%;
  &-wrapper {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 18.59%, rgba(0, 0, 0, 0) 46.65%);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none; } }
  &-img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 24px; }
  &-video {
    max-width: 100%;
    width: 100% !important;
    margin-bottom: 24px; }
  &-date {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #9899AE;
    margin-bottom: 16px; }
  &-title {
    margin-bottom: 16px; } }
