.main {
  position: relative;
  padding-top: 65px;
  width: 100%;
}
.main-btn {
  margin: 25px 0 23px 262px;
}
.table-wrapper {
  height: calc(100vh - 153px);
  overflow-x: scroll;
}
.table-wrapper__modal {
  margin-right: 975px;
}
.table-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px; 
}
.table-wrapper::-webkit-scrollbar-track {
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;
  border-radius: 10px;
}
.table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, #1a4876, #ecf3f7);
}

@media (max-width: 1400px) {
  .table-wrapper__modal {
    margin-right: 800px;
  }
}

@media (max-width: 1024px) {
  .table-wrapper__modal {
    margin-right: 730px;
  }
}
@media (max-width: 950px) {
  .table-wrapper__modal {
    margin-right: 0;
  }
}

@media (max-width: 850px) {
  .main-btn {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}