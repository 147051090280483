@import '../../../../style/colors';

.document {
  background-color: $bgc;
  padding: 60px 0;
  &-block {
    width: 75%; }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: inset 0px -1px 0px #ECE8F0;
    &:hover {
      .document-item__icon {
        fill: $red-dark;
        margin-right: 7px; } }
    &__icon {
      fill: $red;
      width: 28px;
      margin-right: 4px;
      transition: all ease .2s; }

    &__title {
      font-size: 30px;
      line-height: 35px;
      font-weight: normal;
      margin: 0; } } }

@media (max-width: 991.98px) {
  .document-block {
    width: 100%; } }

@media (max-width: 767.98px) {
  .document {
    padding: 40px 0;
    &-item {
      &__title {
        padding-right: 5px;
        width: calc( 100% - 40px ); }
      &:hover {
        .document-item__icon {
          margin-right: 4px; } } } } }

@media (max-width: 319.98px) {
  .document-item__title {
    font-size: 25px;
    line-height: 30px; } }
