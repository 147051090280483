@import '../../style/colors';

.cookie {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: $dark;
  color: #f1f1f1;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 101;
  line-height: initial;
  &-text {
    margin-right: 15px;
    & a {
      color: #f1f1f1;
      text-decoration: underline;
      transition: all ease-in-out .2s;
      margin-left: 5px;
      &:hover {
        opacity: .7; } } }
  &-btn {
    background-color: $red;
    color: #f1f1f1;
    border-radius: 5px;
    min-width: 86px;
    padding: 8px 8px;
    text-align: center;
    cursor: pointer;
    transition: all ease-in-out .2s;
    font-size: 17px;
    white-space: nowrap;
    &:hover, &:active {
      background-color: $red-dark; }
    &.white {
      color: $red;
      background-color: #f1f1f1;
      margin-left: 15px;
      // max-height:
      &:hover, &:active {
        background-color: #dddddd; } } }
  &-btns {
    display: flex;
    align-items: center; } }


@media (max-width: 575.98px) {
  .cookie {
    flex-direction: column;
    &-btns {
      margin-top: 20px; } } }
